import style from './index.module.scss'
import { useState, useEffect } from "react"
import { Tabs } from 'antd'
import '../../styles/animate.min.css'
import { WOW } from 'wowjs'
import Banner from "@/components/Banner"

function DigitalPay() {
    return (
        <div className='modular_one'>
            <div className='te_center f_s36 pa_top50 pa_bottom40 wow fadeInUp'>数字支付</div>
            <div className='modular_one_flex flex justify_between wow fadeInUp'>
                <div className='modular_one_flex_box bg_white bo_radius8 ma_bottom50'>
                    <div className='f_s60 te_center pa_top40 iconfont i-gouwu-'></div>
                    <div className='f_s24 te_center pa_top30'>支付服务</div>
                    <div className='f_s14 te_center pa_top20 pa_lr30'>帮助商家接入支付服务，满足商家在各类交易场景中快速收款需求</div>
                </div>
                <div className='modular_one_flex_box bg_white bo_radius8 ma_bottom50'>
                    <div className='f_s60 te_center pa_top40 iconfont i-shangye-'></div>
                    <div className='f_s24 te_center pa_top30'>数字经营</div>
                    <div className='f_s14 te_center pa_top20 pa_lr30'>助力商家构建数字化经营，实现用户留存与转化，复购与召回</div>
                </div>
                <div className='modular_one_flex_box bg_white bo_radius8 ma_bottom50'>
                    <div className='f_s60 te_center pa_top40 iconfont i-gouwu-2'></div>
                    <div className='f_s24 te_center pa_top30'>智能设备</div>
                    <div className='f_s14 te_center pa_top20 pa_lr30'>为商家铺设智能收款音响，刷脸设备，提升收银前台的用户支付体验和商家收款效率</div>
                </div>
                <div className='modular_one_flex_box bg_white bo_radius8 ma_bottom50'>
                    <div className='f_s60 te_center pa_top40 iconfont i-gouwu-1'></div>
                    <div className='f_s24 te_center pa_top30'>金融服务</div>
                    <div className='f_s14 te_center pa_top20 pa_lr30'>全力帮助商家接入各大金融平台， 实现商家对于资金服务需求</div>
                </div>
            </div>
        </div>
    )
}

function Solution() {
    return (
        <div className='modular_two'>
            <div className='te_center f_s36 pa_top50 pa_bottom30 wow fadeInUp'>解决方案</div>
            <div className='modular_two_flex flex justify_between flex_wrap wow fadeInUp'>
                <div className='modular_two_flex_box ma_bottom20'>
                    <img className='modular_two_flex_img' src={'https://vpay-static.oss-cn-shenzhen.aliyuncs.com/static/official/assets/home/2.png'} alt=""></img>
                    <div className='modular_two_flex_box_text'>
                        <div className='pa_left40 te_white f_s30 ma_top30 pa_top30 fw_bold pa_bottom20'>数字寺院</div>
                        <div className='pa_left40 te_white f_s14'>一站式，整体化帮助寺院实现数字化转型，</div>
                        <div className='pa_left40 te_white f_s14'>轻松接入码牌支付，智能功德箱，数字点灯等寺院佛事场景</div>
                    </div>
                </div>
                <div className='modular_two_flex_box ma_bottom20'>
                    <img className='modular_two_flex_img' src={'https://vpay-static.oss-cn-shenzhen.aliyuncs.com/static/official/assets/home/3.png'} alt=""></img>
                    <div className='modular_two_flex_box_text'>
                        <div className='pa_left40 te_white f_s30 ma_top30 pa_top30 fw_bold pa_bottom20'>社区零售</div>
                        <div className='pa_left40 te_white f_s14'>提供智能收款码牌和商家收款管家，支付宝小程序</div>
                        <div className='pa_left40 te_white f_s14'>智能设备等场景帮助夫妻老婆店实现低费率接入支付，享受支付红利</div>
                    </div>
                </div>
                <div className='modular_two_flex_box ma_bottom20'>
                    <img className='modular_two_flex_img' src={'https://vpay-static.oss-cn-shenzhen.aliyuncs.com/static/official/assets/home/4s.png'} alt=""></img>
                    <div className='modular_two_flex_box_text'>
                        <div className='pa_left40 te_white f_s30 ma_top30 pa_top30 fw_bold pa_bottom20'>无人值守</div>
                        <div className='pa_left40 te_white f_s14'>无人值守平台是一款微信预约小程序，结合智百威商家系统，通过智能设备与系统</div>
                        <div className='pa_left40 te_white f_s14'>为顾客提供便捷服务，实现场所智能化管理。适用棋牌室室、台球室、自习室等</div>
                    </div>
                </div>
                <div className='modular_two_flex_box ma_bottom20'>
                    <img className='modular_two_flex_img' src={'https://vpay-static.oss-cn-shenzhen.aliyuncs.com/static/official/assets/home/5.png'} alt=""></img>
                    <div className='modular_two_flex_box_text'>
                        <div className='pa_left40 te_white f_s30 ma_top30 pa_top30 fw_bold pa_bottom20'>智慧景区</div>
                        <div className='pa_left40 te_white f_s14'>提供智能收款码牌和商家收款管家，</div>
                        <div className='pa_left40 te_white f_s14'>智能设备，助力景区支付提升用户体验，共建智慧新景区</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function AccessVpay() {
    let [type, settype] = useState('1')
    const onChange = (e) => {
        settype(e)
    };
    const gowindow = (e) => {
        window.open(type === '1' ? "https://agent.zbwpay.com/" : "https://merchant.zbwpay.com/")
    };
    return (
        <div className='modular_three'>
            <div className='te_center f_s36 pa_top30 pa_bottom20 wow fadeInUp'>如何接入威支付</div>
            <div className='wow fadeInUp'>
                <Tabs
                    defaultActiveKey={type}
                    onChange={onChange}
                    className='f_s24'
                    items={[{
                        label: `代理商`,
                        key: '1',
                    }, {
                        label: `商户`,
                        key: '2',
                    }]}
                />
                <div className='modular_three_flex flex justify_between pa_top20'>
                    <div className='modular_three_flex_box modular_three_flex_img1'>
                        <div className='modular_three_flex_box_text'>
                            <div className='f_s20 pa_top50 pa_bottom20'>{type === '1' ? '入驻资料准备' : '商户开户'}</div>
                            <div className='f_s14 te_black9'>{type === '1' ? '入驻代理商平台前，提前准备' : '开通支付的商户可直接找当'}</div>
                            <div className='f_s14 te_black9'>{type === '1' ? '好开户所需资料信息，联系商' : '地代理商或直接联系我司即'}</div>
                            <div className='f_s14 te_black9'>{type === '1' ? '务进行签约' : '可开户'}</div>
                        </div>
                    </div>
                    <div className='modular_three_flex_box modular_three_flex_img2'>
                        <div className='modular_three_flex_box_text'>
                            <div className='f_s20 pa_top50 pa_bottom20'>{type === '1' ? '注册代理商账号' : '入驻认证'}</div>
                            <div className='f_s14 te_black9'>{type === '1' ? '登录代理商平台，录入开户所' : '开户成功的商户需要准备支付进'}</div>
                            <div className='f_s14 te_black9'>{type === '1' ? '需信息，即可自助开户' : '件的企业和法人资料登录商户平'}</div>
                            <div className='f_s14 te_black9'>{type === '1' ? '' : '台或者代理商平台协助入驻认证'}</div>
                        </div>
                    </div>
                    <div className='modular_three_flex_box modular_three_flex_img3'>
                        <div className='modular_three_flex_box_text'>
                            <div className='f_s20 pa_top50 pa_bottom20'>{type === '1' ? '入驻代理商平台' : '收款开通'}</div>
                            <div className='f_s14 te_black9'>{type === '1' ? '使用自助注册的代理商账号信' : '入驻成功后，商户平台配置对应'}</div>
                            <div className='f_s14 te_black9'>{type === '1' ? '息，即可登录代理商平台' : '的收款账号，即成功开通支付'}</div>
                        </div>
                    </div>
                </div>
                <div className='modular_three_btn bo_radius4 bg_green te_white f_s24 fw_bold te_center' onClick={gowindow}>立即接入威支付</div>
            </div>
        </div>
    )
}

function Home() {
    useEffect(() => {
        var wow = new WOW({
            boxClass: 'wow',
            animateClass: 'animated',
            offset: 0,
            mobile: true,
            live: true
        });
        wow.init()
    }, [])
    return (
        <div>
            <div className={style.page_content}>
                <Banner img={'https://vpay-static.oss-cn-shenzhen.aliyuncs.com/static/official/assets/home/1.png'} notice={true}></Banner>
                <DigitalPay></DigitalPay>
                <Solution></Solution>
                <AccessVpay></AccessVpay>
            </div>
        </div>
    )
}

export default Home