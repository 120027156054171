import style from './index.module.scss'
import { useEffect } from "react"
import '../../styles/animate.min.css'
import { WOW } from 'wowjs'
import Banner from "@/components/Banner"
const items = [{
    label: '数字寺院',
    icon: 'i-a-ziyuan48',
    children: [{
        img: 'https://vpay-static.oss-cn-shenzhen.aliyuncs.com/static/official/assets/solution/2.png',
        name: "H5官网/小程序网站",
        tips: "微信公众号体现内宣传寺院的法务，实现香客移动端 预约，捐赠"
    }, {
        img: 'https://vpay-static.oss-cn-shenzhen.aliyuncs.com/static/official/assets/solution/3.png',
        name: "立牌码扫码",
        tips: "香客手机扫码就能捐赠功德金额，支持微信，支付宝，云闪付扫码支付"
    }, {
        img: 'https://vpay-static.oss-cn-shenzhen.aliyuncs.com/static/official/assets/solution/4.png',
        name: "功德箱捐赠",
        tips: "功德箱数字大屏宣传寺院介绍，支持捐赠播放和香客霸屏和捐赠卡牌打印"
    }]
}, {
    label: '社区零售',
    icon: 'i-dianpu-copy',
    children: [{
        img: 'https://vpay-static.oss-cn-shenzhen.aliyuncs.com/static/official/assets/solution/2.png',
        name: "零售小店",
        tips: "支付宝零售小店，助力零售商家实现数字化转型，共性私域流量红利"
    }, {
        img: 'https://vpay-static.oss-cn-shenzhen.aliyuncs.com/static/official/assets/solution/6.png',
        name: "线上商城",
        tips: "在线商城，助力商家移动化引流，轻松实现卖货，分销，会员等业务"
    }, {
        img: 'https://vpay-static.oss-cn-shenzhen.aliyuncs.com/static/official/assets/solution/7.png',
        name: "移动收款",
        tips: "威付商户管家轻松搞定商家移动收款需要，告别使用繁琐的收银管理软件"
    }]
}, {
    label: '无人值守',
    icon: 'i-wurenzhishou',
    children: [{
        img: 'https://vpay-static.oss-cn-shenzhen.aliyuncs.com/static/official/assets/solution/8s.png',
        name: "棋牌室",
        tips: "帮助棋牌室实现数字化、智能化管理，免费接入抖音、快手、美团等公域流量"
    }, {
        img: 'https://vpay-static.oss-cn-shenzhen.aliyuncs.com/static/official/assets/solution/12.png',
        name: "台球室",
        tips: "致力于台球室无缝接入先进的互联网运营模式，以实现开源节流、降本增效"
    }, {
        img: 'https://vpay-static.oss-cn-shenzhen.aliyuncs.com/static/official/assets/solution/10s.png',
        name: "自习室",
        tips: "一改传统自习室的人工管理，实现数字化自助模式，帮助自习室提高收益"
    }]
}, {
    label: '智慧景区',
    icon: 'i-jingqu',
    children: [{
        img: 'https://vpay-static.oss-cn-shenzhen.aliyuncs.com/static/official/assets/solution/9.png',
        name: "立牌支付",
        tips: "一个码牌支持微信，支付宝，云闪付扫码支付，帮助景区小店实现收银需要"
    }, {
        img: 'https://vpay-static.oss-cn-shenzhen.aliyuncs.com/static/official/assets/solution/11.png',
        name: "移动收款",
        tips: "威付商户管家轻松搞定商家移动收款需要，告别使用繁琐的收银管理软件"
    }]
}]

function Solution() {
    useEffect(() => {
        var wow = new WOW({
            boxClass: 'wow',
            animateClass: 'animated',
            offset: 0,
            mobile: true,
            live: true
        });
        wow.init()
    }, [])

    return (
        <div>
            <div className={style.page_content}>
                <Banner img={'https://vpay-static.oss-cn-shenzhen.aliyuncs.com/static/official/assets/solution/1.png'}></Banner>
                <div className='modular_box'>
                    {
                        items.map((item, index) => {
                            return (
                                <div key={index} className='modular_box_flex'>
                                    <div className='flex pa_bottom40 wow fadeInUp'>
                                        <div className={`iconfont ${item.icon} f_s28`}></div>
                                        <span className='f_s24 pa_left20'>{item.label}</span>
                                    </div>
                                    <div className='flex align_center'>
                                        {
                                            item.children.map((inner, indexs) => {
                                                return (
                                                    <div key={indexs} className='modular_box_flex_content bg_white bo_radius8 wow fadeInUp'>
                                                        <div className='modular_box_flex_content_top'>
                                                            <img src={inner.img} alt="" />
                                                            <div className='modular_box_flex_content_top_tips te_white f_s20 fw_bold'>{inner.name}</div>
                                                        </div>
                                                        <div className='modular_box_flex_content_bottom flex align_center'>{inner.tips}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Solution