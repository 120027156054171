import style from './index.module.scss'
import { useEffect, useState } from "react"
import '../../styles/animate.min.css'
import { WOW } from 'wowjs'
import { Table, Pagination, message } from 'antd';
import { useHistory } from "react-router-dom"
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import axios from 'axios';

const columns = [{
    title: '平台公告',
    dataIndex: 'noticeName',
    key: 'id',
    align: 'left',
}, {
    title: '日期',
    dataIndex: 'createTime',
    key: 'id',
    align: 'right'
}]

function ListBox({ setSelected }) {
    const history = useHistory()
    const onSelectRow = async (record) => {
        const res = await axios({
            method: 'post',
            url: 'https://isv.zbwpay.com/vpayapi/vpay/biz/api/srv-sys-notice-by-id/1',
            headers: { 'Content-Type': 'application/json' },
            data: {
                id: record.id
            }
        })
        if (res.data.errCode == 0) {
            setSelected(res.data.data)

        } else {
            messageApi.open({
                type: 'error',
                content: res.data.errMsg,
            });
        }

    };
    const [dataSource, setDataSource] = useState([])
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState({ currentPage: 1, pageSize: 10 })
    const [messageApi, contextHolder] = message.useMessage();

    const getlist = async (e) => {
        const res = await axios({
            method: 'post',
            url: 'https://isv.zbwpay.com/vpayapi/vpay/biz/api/srv-sys-notice-list-page/1',
            headers: { 'Content-Type': 'application/json' },
            data: {
                bool: false,
                noticeType: 5,
                ...page,
                ...e
            }
        })
        if (res.data.errCode == 0) {
            setDataSource(res.data.data.list)
            setTotal(res.data.data.page.totalCount)
        } else {
            messageApi.open({
                type: 'error',
                content: res.data.errMsg,
            });
        }
    }

    useEffect(() => {
        if (history.location.search) {
            let id = history.location.search.split('=')[1]
            onSelectRow({ id })
        }
        getlist()
    }
        , []);
    const pageOnchange = (currentPage, pageSize) => {
        if (currentPage == 0) {
            currentPage = 1
        }
        setPage({
            currentPage: currentPage,
            pageSize: pageSize
        })
        getlist({ currentPage, pageSize })
    }
    return (
        <div style={{ position: 'absolute' }}>
            {contextHolder}
            <div className='modular_box_title flex align_center justify_between'>
                <div className='f_s18 fw_bold'>平台公告</div>
                <div className='f_s16 fw_bold te_green' onClick={() => { history.push('/Home') }} style={{ 'cursor': 'pointer' }}>返回</div>
            </div>
            <div className='pa_lr40 modular_box_table'>
                <Table
                    onRow={(record) => ({ onClick: () => onSelectRow(record) })}
                    dataSource={dataSource}
                    columns={columns}
                    showHeader={false}
                    pagination={false}
                    scroll={{ x: 'max-content', y: 622 }}
                    rowKey={record => record.id}
                />

            </div>
            <div className='pa_lr40 pa_tb20 te_right'>
                <Pagination
                    defaultCurrent={page.currentPage}
                    defaultPageSize={page.pageSize}
                    total={total}
                    pageSizeOptions={[1, 10, 50, 100]}
                    showQuickJumper
                    showSizeChanger
                    // onShowSizeChange={sizeChange}
                    onChange={pageOnchange}
                />
            </div>
        </div>
    )
}

function InfoBox({ selected, setSelected }) {
    const history = useHistory()
    return (
        <div style={{ position: 'absolute' }}>
            <div className='modular_box_title flex align_center justify_center'>
                <div className='f_s18 fw_bold'>{selected.noticeName}</div>
            </div>
            <div className='pa_lr40 modular_box_table' >
                <div className='pa_top40 modular_box_table_html f_s18' dangerouslySetInnerHTML={{ __html: selected.content }}></div>
            </div>
            <div className='modular_box_btn bo_radius4 te_center f_s18 te_black6' onClick={() => { setSelected(''); history.push('/Notice') }}>返回</div>
        </div>
    )
}

function Solution() {
    const [selected, setSelected] = useState('');
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        var wow = new WOW({
            boxClass: 'wow',
            animateClass: 'animated',
            offset: 0,
            mobile: true,
            live: true
        });
        wow.init()
    }, [])

    return (
        <div>
            <div className={style.page_content}>
                <div className='modular_box bg_white wow fadeInUp'>
                    <TransitionGroup>
                        <CSSTransition key={!!selected} classNames={'fade'} timeout={500} >
                            {
                                !!selected ? <InfoBox setSelected={setSelected} selected={selected}></InfoBox> : <ListBox setSelected={setSelected}></ListBox>
                            }
                        </CSSTransition>
                    </TransitionGroup>
                </div>
            </div>
        </div>
    )
}

export default Solution