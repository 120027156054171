// 页面顶部模块
import style from './index.module.scss'
import { useHistory, useLocation } from "react-router-dom"
import React, { useState, useEffect } from 'react';
const items = [{
    label: '首页',
    key: '/Home',
}, {
    label: '数字支付',
    key: 'app',
    children: [{
        label: '支付服务',
        key: '/payment',
    }, {
        label: '智能设备',
        key: '/intelligent',
    }]
}, {
    label: '解决方案',
    key: '/Solution',
}, {
    label: (<a href="http://www.bw8848.com" target="_blank" rel="noopener noreferrer">关于我们</a>),
    key: 'alipay',
}];
function Pagehead() {
    const history = useHistory()
    const location = useLocation()
    const [current, setCurrent] = useState('Home');
    const [open, setopen] = useState(false);
    useEffect(() => {
        history.push('/')
    }, [])
    useEffect(() => {
        setCurrent(location.pathname)
    }, [location]);
    // 一级菜单
    const delFolder = (e) => {
        if (e.key !== 'alipay' && e.key !== 'app' ) {
            history.push(e.key)
            setCurrent(e.key)
            setopen(false)
        }
    };
    // 鼠标移入移出数字支付
    const mouseenterdiv = (e) => {
        if(e.key === 'app'){
            setopen(true)
        }else{
            setopen(false)
        }
    };
    const mouseoutdiv = (e) => {
        setopen(false)
    };
    const gomerchant = (e) => {
        window.open("https://merchant.zbwpay.com/")
    };
    const goagent = (e) => {
        window.open("https://agent.zbwpay.com/")
    };

    return (
        <div>
            <div className={style.location}>
                <div className='tips'></div>
                <div className='page_head'>
                    <img src={require('@/assets/logo.png')} alt="" />
                    <div className='flex align_center'>
                        <div className='flex align_center ma_right50 pa_right50'>
                            {
                                items.map((item, index) => {
                                    return (
                                        <div key={index} className='menu_box ma_right30' onClick={(e)=>delFolder(item,e)} onMouseEnter={(e)=>mouseenterdiv(item,e)}>
                                            <div className={`menu_title f_s18 ${current === item.key || (item.key === 'app' && (current === '/payment' || current === '/intelligent')) ? 'te_green' : 'te_black3'}`}>{item.label}</div>
                                            <div className={`bo_radius6 ${current === item.key || (item.key === 'app' && (current === '/payment' || current === '/intelligent')) ? 'in' : 'on'}  `}></div>
                                            {
                                                item.children &&
                                                <div className={`dropdown_box ${open ? '' :'dropdown_box_hi'}`} onMouseLeave ={(e)=>mouseoutdiv(item,e)}>
                                                    {
                                                        item.children.map((inner, indexs) => {
                                                            return (
                                                                <div key={indexs} className={`dropdown_box_title ${inner.key == current ? 'dropdown_box_title_select' : ''}`} onClick={(e)=>delFolder(inner,e)}>{inner.label}</div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className='head_btn te_green bg_white bo_radius4 ma_right20 f_s16 te_center' onClick={goagent}>伙伴登录</div>
                        <div className='head_btn te_white bg_green bo_radius4 ma_right20 f_s16 te_center' onClick={gomerchant}>商户登录</div>
                    </div>
                </div>
            </div>
            <div style={{ height: 80 }}></div>
        </div>

    )
}

export default Pagehead