// banner
import style from './index.module.scss'
import { useHistory } from "react-router-dom"
import { message } from 'antd';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { render } from 'react-dom';

function Pagebottom(props) {
    const [messageApi, contextHolder] = message.useMessage();
    const [dataSource, setDataSource] = useState([])
    const history = useHistory()
    const getlist = async (e) => {
        const res = await axios({
            method: 'post',
            url: 'https://isv.zbwpay.com/vpayapi/vpay/biz/api/srv-sys-notice-list-page/1',
            headers: { 'Content-Type': 'application/json' },
            data: {
                bool: false,
                noticeType: 5,
                currentPage: 1,
                pageSize: 1
            }
        })
        if (res.data.errCode == 0) {
            setDataSource(...res.data.data.list)
        } else {
            messageApi.open({
                type: 'error',
                content: res.data.errMsg,
            });
        }
    }

    useEffect(() => {
        getlist()
    }, [])
    return (
        <div className={style.banner}>
            {contextHolder}
            <img className='banner_img' src={props.img} alt=""></img>
            <div className='banner_box flex flex_column align_center justify_center'>
                <div className='banner_box_title te_white flex align_center f_s20 wow fadeInDown'>{props.text1}</div>
                {
                    props.text2 && <div className={`banner_box_tips te_white pa_top50 wow fadeInUp ${props.notice ? 'pa_bottom50' : 'pa_bottom20'}`}>{props.text2}</div>
                }
            </div>
            {
                props.notice && dataSource &&
                <div className='banner_bottom'>
                    <div className='banner_bottom_box flex align_center justify_between te_white f_s16'>
                        <div onClick={() => { history.push('/Notice?id=' + dataSource.id) }}>公告 <span className='ma_lr20'>|</span> <span className='item'>{dataSource.noticeName}</span></div>
                        <div onClick={() => { history.push('/Notice') }} className='item'>{'更多 >>'}</div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Pagebottom