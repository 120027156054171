// 数字支付tabbar
import { useState } from 'react'
import style from './index.module.scss'

function Contenttab(params) {
    return (
        <div className={style.contenttab}>
            <div className='contenttab_box'>
                <ul className='flex justify_around f_s18 wow fadeIn'>
                    <li onClick={() => { document.documentElement.scrollTop = 400 }}>{params.tabArr[0]}</li>
                    <li onClick={() => { document.documentElement.scrollTop = 1200 }}>{params.tabArr[1]}</li>
                    <li onClick={() => { document.documentElement.scrollTop = 1900 }}>{params.tabArr[2]}</li>
                    <li onClick={() => { document.documentElement.scrollTop = 2600 }}>{params.tabArr[3]}</li>
                </ul>
            </div>
        </div>
    )
}

export default Contenttab