import style from './index.module.scss'
import { useEffect } from "react"
import '../../styles/animate.min.css'
import { WOW } from 'wowjs'
import Contenttab from '@/components/Contenttab'
import Banner from "@/components/Banner"

function IntelligentDevice(params) {
    useEffect(() => {
        var wow = new WOW({
            boxClass: 'wow',
            animateClass: 'animated',
            offset: 0,
            mobile: true,
            live: true
        });
        wow.init()
    }, [])
    return (
        <div className={style.device}>
            <Banner img={'https://vpay-static.oss-cn-shenzhen.aliyuncs.com/static/official/assets/service/yinjian.png'}></Banner>
            <Contenttab tabArr={['数字云音响', '智能云打印', '威支付智能BOX', '刷脸支付设备']} ></Contenttab>
            <div className='code'>
                <div className='left wow fadeInLeft' style={{ marginRight: 120 }}>
                    <div className='f_s36 ma_bottom20'>数字云音响</div>
                    <div style={{ color: '#333' }}>收款即播报，笔笔无漏报，音量更宏大，声音更清晰</div>
                    <div className='flex ma_top50 align_center ma_left10'>
                        <div className=' f_s28 iconfont  te_green i-dianchi  ma_right20'></div>
                        <div>
                            <div className='f_s18'>大容量电池</div>
                            <div>内置2000mA/h高性能电池（18650）超低功耗待机5-7天</div>
                        </div>
                    </div>
                    <div className='flex ma_top50 align_center ma_left10'>
                        <div className=' f_s28 iconfont  te_green i-Cellular4G  ma_right20'></div>
                        <div>
                            <div className='f_s18'>4G/4G+WiFi版本可选</div>
                            <div>WIFI、4G通讯可选，自由组合，不同环境下自动切换，满足不同需求</div>
                        </div>
                    </div>
                    <div className='flex ma_top50 align_center ma_left10'>
                        <div className=' f_s28 iconfont  te_green i-laba  ma_right20'></div>
                        <div>
                            <div className='f_s18'>大喇叭音量可调</div>
                            <div>4欧3W全频喇叭，声音哄亮大小可调，音质好，无论什么环境都能听得清</div>
                        </div>
                    </div>
                    <div className='flex ma_top50 align_center ma_left10'>
                        <div className=' f_s28 iconfont  te_green i-RectangleCopy18  ma_right20'></div>
                        <div>
                            <div className='f_s18'>个性化管理</div>
                            <div>支持远程升级，支持自带小码牌或可拆卸式分体大，码牌个性化管理</div>
                        </div>
                    </div>
                </div>
                <img className='wow fadeInRight' src={'https://vpay-static.oss-cn-shenzhen.aliyuncs.com/static/official/assets/service/yinxiang.png'} alt="" style={{ height: 500 }} />
            </div>
            <div className='code'>
                <img className='wow fadeInLeft' src={'https://vpay-static.oss-cn-shenzhen.aliyuncs.com/static/official/assets/service/dayin.png'} alt="" style={{ height: 500, marginRight: 120 }} />
                <div className='left wow fadeInRight'>
                    <div className='f_s36 ma_bottom20'>智能云打印</div>
                    <div style={{ color: '#333' }}>无需电脑，无需手机，无需人工，付款成功自动打印支付小票，AI语音播报，边播报边打印，收银效率更高效</div>
                    <div className='flex ma_top50 align_center ma_left10'>
                        <div className=' f_s28 iconfont  te_green i-RectangleCopy15  ma_right20'></div>
                        <div>
                            <div className='f_s18'>AI语音播报</div>
                            <div>真人语音播报，接单后实现智能语音播报，可播报订单来源、名称、数量、金额等信息</div>
                        </div>
                    </div>
                    <div className='flex ma_top50 align_center ma_left10'>
                        <div className=' f_s28 iconfont  te_green i-RectangleCopy19  ma_right20'></div>
                        <div>
                            <div className='f_s18'>多平台接单</div>
                            <div>可同时接收外卖、堂食、支付等多个不同平台上的订单并行自动打印</div>
                        </div>
                    </div>
                    <div className='flex ma_top50 align_center ma_left10'>
                        <div className=' f_s28 iconfont  te_green i-RectangleCopy  ma_right20'></div>
                        <div>
                            <div className='f_s18'>全自动打单</div>
                            <div>多平台快速自动接单并打印，性能稳定，打印清晰</div>
                        </div>
                    </div>
                    <div className='flex ma_top50 align_center ma_left10'>
                        <div className=' f_s28 iconfont  te_green i-RectangleCopy21  ma_right20'></div>
                        <div>
                            <div className='f_s18'>多重防漏单</div>
                            <div>支持在断电/缺纸时一键重打，支持双网无线通讯、GPRS/WIFI，实时检测网络状况，智能切换网络</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='code'>
                <div className='left wow fadeInLeft' style={{ marginRight: 120 }}>
                    <div className='f_s36 ma_bottom20'>威支付智能BOX</div>
                    <div style={{ color: '#333' }}>支付微信，支付宝，云闪付多款应用聚合收款，支持急速扫码，可识别一维码和二维码，付款到账实时语音播报，防止顾客不付款，收款不漏单</div>
                    <div className='flex ma_top50 align_center ma_left10'>
                        <div className=' f_s28 iconfont  te_green i-saoyisao1  ma_right20'></div>
                        <div>
                            <div className='f_s18'>扫码秒识别</div>
                            <div>极速扫码，秒识别，收付款无需等待</div>
                        </div>
                    </div>
                    <div className='flex ma_top50 align_center ma_left10'>
                        <div className=' f_s28 iconfont  te_green i-guochengjiankong-duopingxianshi  ma_right20'></div>
                        <div>
                            <div className='f_s18'>语音屏显双重提醒</div>
                            <div>收款语音播报，收款金额屏幕显示，双重保证</div>
                        </div>
                    </div>
                    <div className='flex ma_top50 align_center ma_left10'>
                        <div className=' f_s28 iconfont  te_green i-shebei  ma_right20'></div>
                        <div>
                            <div className='f_s18'>支持手持和台式</div>
                            <div>支持手持主动扫码或放置台桌上让客户扫码，方便便捷</div>
                        </div>
                    </div>
                    <div className='flex ma_top50 align_center ma_left10'>
                        <div className=' f_s28 iconfont  te_green i-quanping-fangda-05  ma_right20'></div>
                        <div>
                            <div className='f_s18'>双屏设计</div>
                            <div>主显屏供商家输入金额，客显屏给顾客展示付款金额。</div>
                        </div>
                    </div>
                </div>
                <img className='wow fadeInRight' src={'https://vpay-static.oss-cn-shenzhen.aliyuncs.com/static/official/assets/service/box.png'} alt="" style={{ height: 500 }} />
            </div>
            <div className='code'>
                <img className='wow fadeInLeft' src={'https://vpay-static.oss-cn-shenzhen.aliyuncs.com/static/official/assets/service/shualian.png'} alt="" style={{ height: 500, marginRight: 120, marginBottom: 140 }} />
                <div className='left wow fadeInRight'>
                    <div className='f_s36 ma_bottom20'>刷脸支付设备</div>
                    <div style={{ color: '#333' }}>基于人工智能、生物识别、3D传感技术实现刷脸也可以支付，从此告别传统扫码和现金支付</div>
                    <div className='flex ma_top50 align_center ma_left10'>
                        <div className=' f_s28 iconfont  te_green i-RectangleCopy1  ma_right20'></div>
                        <div>
                            <div className='f_s18'>金融级安全性</div>
                            <div>刷脸支付设备配备3D红外深度摄像头，人脸识别时能进行活体检测，全方面检测支付人的身份信息，避免支付错误</div>
                        </div>
                    </div>
                    <div className='flex ma_top50 align_center ma_left10'>
                        <div className=' f_s28 iconfont  te_green i-RectangleCopy17  ma_right20'></div>
                        <div>
                            <div className='f_s18'>金融级准确性</div>
                            <div>刷脸支付采用金融级人脸识别技术，并为刷脸支付做了独特优化，识别准确率为99.99%</div>
                        </div>
                    </div>
                    <div className='flex ma_top50 align_center ma_left10'>
                        <div className=' f_s28 iconfont  te_green i-RectangleCopy20  ma_right20'></div>
                        <div>
                            <div className='f_s18'>动态验证口令</div>
                            <div>刷脸支付采用手机号动态口令方案，根据人脸识别的准确性来动态判断是否需要付款人输入手机号进行验证</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default IntelligentDevice